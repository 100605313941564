"use client";
import { Fragment, useState } from "react";
import Image from "next/image";
import { Stack, CardHeader, Typography, Box, Grid } from "@mui/material";

import { useStore } from "../../service/mobx";
import Star from "../Star";
import FilledCard from "../Card/Filled";
import { textRank } from "../../service/mobx/utilities";
import { ModelCardSize1 } from "./Model";
import GameBadge from "../GameBadge";
import { menuOptionsPublisher } from "../Menu/menus/Search/Publisher";
import { Tasks } from "./Paper";
import { RenderPaperTitle } from "../Agent/Messages/Bytez";

function CardProductSize2({ paper, tags = false, prefetch }) {
  const [imgTaskFailed, setTaskFailed] = useState(paper.imgTask === undefined);
  const [imgPaperFailed, setPaperFailed] = useState(
    paper.imgPaper === undefined
  );

  return (
    <Card paper={paper} prefetch={prefetch}>
      <Grid
        container
        columns={10}
        sx={{ "&:hover .overlay": { bgcolor: "unset" } }}
        flexDirection={{ compact: "column", expanded: "row" }}
      >
        {imgPaperFailed ? null : (
          <Grid item compact={12}>
            <Box
              m={-2}
              mb={2}
              overflow="hidden"
              position="relative"
              height={160}
              borderRadius={theme => theme.shape.md.top}
              sx={{
                "& img": {
                  width: "100%",
                  display: "block",
                  objectFit: "cover",
                  objectPosition: "top center"
                }
              }}
            >
              <Image
                fill
                src={imgTaskFailed ? paper.imgPaper : paper.imgTask}
                alt={paper.title}
                sizes="(max-width: 1600px) 320px, (max-width: 1200px) 475px, 500px"
                onError={() => {
                  if (!imgTaskFailed) {
                    setTaskFailed(true);
                  } else {
                    setPaperFailed(true);
                  }
                }}
              />
              <Box
                className="overlay"
                sx={theme => ({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  mixBlendMode: "multiply",
                  bgcolor: theme.palette.alpha(
                    theme.palette.surface.container.color,
                    0.3
                  ),
                  transition: theme.transitions.create("all")
                })}
              />
            </Box>
          </Grid>
        )}
        <Grid item compact={12} medium>
          {/* <span> */}
          <Header org publisher paper={paper} />
          {/* </span> */}
          <Models paper={paper} />
          {tags ? (
            <Tasks
              small
              paddingLeft={!!paper.scoreTrending || !!paper.scoreSota}
              tags={paper.tags}
            />
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
}
function CardProductSize3({ paper }) {
  // const Status = null;

  return (
    <Card paper={paper}>
      <Header org authors publisher paper={paper} />
      <Models paper={paper} />
    </Card>
  );
}

function CardProductSize4({ paper }) {
  // const Status = null;

  return (
    <Card paper={paper}>
      <Header org authors publisher paper={paper} />
      <Summary paper={paper} />
      <Models paper={paper} />
    </Card>
  );
}

export { CardProductSize2, CardProductSize3, CardProductSize4 };

function Card({ paper, children, ...props }) {
  const { analytics } = useStore();
  const { publisher, paperID } = paper;

  return (
    <FilledCard
      tone="surface.container.color"
      href={`docs/${publisher}/${paperID}/paper`}
      onClick={() => {
        analytics.track.event("Paper Click", { publisher, paperID });
      }}
      {...props}
    >
      {children}
    </FilledCard>
  );
}
const Models =
  (() => null) ||
  (({ paper }) =>
    paper.models?.length ? (
      <Stack
        spacing={1}
        direction="row"
        flexWrap="wrap"
        pl={paper.status ? 7 : undefined}
        sx={{
          "& > *": {
            mt: 1
          }
        }}
      >
        {paper.models.map(model => (
          <ModelCardSize1 key={model.id} model={model} />
        ))}
      </Stack>
    ) : undefined);

function SubHeader({ paper, org = false, authors = false, publisher = false }) {
  const { utilities } = useStore();

  try {
    const authorArray = paper.authorArray ?? JSON.parse(paper.authors);
    const remainingAuthorsLength = authorArray.slice(1).length;
    var authorString = `${authorArray.slice(0, 1).join(", ")}${
      remainingAuthorsLength ? " + " + remainingAuthorsLength : ""
    }`;
  } catch (error) {
    // console.error(error);
    // console.warn(paper?.authors ?? paper);
  }
  const items = [
    utilities.paperTime(paper.updated || paper.published),
    org ? paper.org : undefined,
    authors ? authorString : undefined,
    publisher ? menuOptionsPublisher[paper.publisher] : undefined
  ].filter(defined => defined);

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" width="100%">
      {items.map((content, index, array) => (
        <Fragment key={index}>
          {content}
          {index + 1 === array.length ? undefined : (
            <Box component="span" mx={0.5}>
              ·
            </Box>
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
const Header = ({ paper, size = "medium", ...subheaderProps }) => (
  <CardHeader
    avatar={
      <GameBadge
        scoreSota={paper.scoreSota}
        scoreTrending={paper.scoreTrending}
        models={paper.models}
      />
    }
    title={<RenderPaperTitle paperTitle={paper.title} />}
    titleTypographyProps={{
      color: "surface.on.color",
      sx: theme => theme.typography.lineClamp(3),
      variant: "titleMd",
      typography: {
        compact: "titleMd",
        expanded:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : "titleMd"
      }
    }}
    subheader={<SubHeader paper={paper} size={size} {...subheaderProps} />}
    subheaderTypographyProps={{
      gutterBottom: true,
      color: "surface.on.color",
      variant: size === "small" ? "bodySm" : "bodyMd"
    }}
    action={
      <Box px={1}>
        <Star size={size} entity={paper} />
      </Box>
    }
    sx={{ p: 0, alignItems: "flex-start" }}
  />
);

function Summary({ paper, size, sentences = 2 }) {
  const mlSummary = paper?.summaries?.[0]?.summary;
  const summary = textRank(mlSummary ?? paper.summary, sentences);

  return summary ? (
    <Typography
      paragraph
      color="surface.on.color"
      variant="paperBody2"
      sx={
        size === 3
          ? theme => theme.typography.lineClamp(2)
          : theme => theme.typography.lineClamp(7)
      }
    >
      {summary}
    </Typography>
  ) : null;
}
