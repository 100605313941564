"use client";
import { Fragment, useState } from "react";
import {
  Stack,
  CardHeader,
  Typography,
  CardMedia,
  Box,
  Unstable_Grid2 as Grid,
  Slider,
  IconButton,
  Collapse,
  TextField,
  useTheme
} from "@mui/material";
import { ExpandLess as ExapndIcon } from "@mui/icons-material";

import { useStore } from "../../service/mobx";
import Star from "../Star";
import FilledCard from "./Filled";
import StackOverflow from "../StackOverflow";
import Popup from "../Popup";
import ChipAssist from "../Chip/Assist";
import { TaskSize1 } from "./Task";
import { PaperCardSize1 } from "./Paper";
import GameBadge from "../GameBadge";

function ModelCardSize1({ model }) {
  // const Icon = user.following.has(task) ? OnIcon : OffIcon;

  return (
    <Popup maxWidth={640} Component={() => <ModelCardSize5 model={model} />}>
      <div>
        <ChipAssist
          // StartIcon={Icon}
          label={model?.alias}
          onClick={() => {
            // analytics.track.event
            // user
          }}
        />
      </div>
    </Popup>
  );
}
function ModelCardSize2({ model, disabled }) {
  //
  return (
    <Card disabled={disabled} model={model}>
      <Header delta size="small" model={model} />
    </Card>
  );
}
function ModelCardSize3({ model, disabled }) {
  // model = { ...model, status: paper.status };
  //
  return (
    <Card disabled={disabled} model={model}>
      <Header size="small" model={model} />
      <Performance model={model} />
    </Card>
  );
}
function ModelCardSize4({ model, paper }) {
  model.paper = paper;
  //
  return (
    <Card>
      <Header publisher runs model={model} />
      <Performance model={model} />
      <Stack direction="row" mt={2} justifyContent="space-between" width="100%">
        <StackOverflow Card={TaskSize1} cards={model.tasks} entityName="tag" />
        <PaperCardSize1 paper={model.paper} />
      </Stack>
    </Card>
  );
}
function ModelCardSize5({ model, paper }) {
  const src = `https://replicate.com/static/home/sdxl.532cebb4a97a.jpg`;
  model.paper = paper;

  //
  return (
    <Card>
      <Grid container sx={{ maxWidth: 640 }}>
        <Grid compact={6} pr={2}>
          <CardMedia
            component="img"
            src={src}
            sx={{
              width: "100%",
              borderRadius: theme => theme.shape.lg.top
              // objectFit: "contain"
            }}
          />
          <TextField
            fullWidth
            placeholder={`Try ${model.name}`}
            sx={theme => ({
              mt: 2,
              height: 48,
              "& fieldset": { border: "none" },
              bgcolor: "surface.container.high",
              borderRadius: theme.shape.round
            })}
            inputProps={{
              sx: {
                height: 16,
                borderRadius: theme => theme.shape.round,
                "&:focus": {
                  bgcolor: "surface.container.highest"
                }
              }
            }}
          />
        </Grid>
        <Grid compact={6} display="flex" height="auto" flexDirection="column">
          <Header license publisher runs model={model} />
          <Performance model={model} />
          <Stack direction="row" justifyContent="space-between">
            <StackOverflow
              Card={TaskSize1}
              cards={model.tasks}
              entityName="tag"
            />
            <PaperCardSize1 paper={model.paper} />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
export {
  ModelCardSize1,
  ModelCardSize2,
  ModelCardSize3,
  ModelCardSize4,
  ModelCardSize5
};

function Stats({ value, position, reverse = false, bounds, labelText }) {
  const [min, lowerBound, upperBound, max] = bounds;
  const {
    palette: { colors }
  } = useTheme();
  const tone = 60;
  const yellow = "#fbbc04" || colors.warning[80];
  const green = "#34a853" || colors.success[tone];
  const red = "#ea4335" || colors.error[tone];

  return (
    <Grid
      container
      mt={2}
      columns={100}
      spacing={0.5}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Grid compact={25} width="25%">
        {position === "low" ? (
          <ModifiedSlider
            min={min}
            max={lowerBound - 1}
            value={value}
            position={position}
            labelText={labelText}
            color={reverse ? green : red}
          />
        ) : (
          <Bar color={reverse ? green : red} />
        )}
      </Grid>
      <Grid compact={50} width="50xz%">
        <ModifiedSlider
          marks
          color={yellow}
          min={lowerBound}
          max={upperBound}
          value={position === "typical" ? value : undefined}
          position={position}
          labelText={labelText}
        />
      </Grid>
      <Grid compact={25} width="25%">
        {position === "high" ? (
          <ModifiedSlider
            min={upperBound + 1}
            max={max}
            value={value}
            position={position}
            labelText={labelText}
            color={reverse ? red : green}
          />
        ) : (
          <Bar color={reverse ? red : green} />
        )}
      </Grid>
    </Grid>
  );
}
const Card = ({ children, disabled, model }) => (
  <FilledCard
    disabled={disabled}
    href={
      model.paper?.publisher
        ? `/docs/${model.paper.publisher}/${model.paper.paperID}/model`
        : undefined
    }
    tone="surface.container.low"
  >
    {children}
  </FilledCard>
);
const Bar = ({ color }) => (
  <Box
    sx={theme => ({
      height: 4,
      width: "100%",
      bgcolor: color,
      display: "inline-flex",
      borderRadius: theme.shape.round
    })}
  />
);

const ModifiedSlider = ({
  value,
  color,
  position,
  labelText,
  min,
  max,
  marks
}) => (
  <Slider
    disabled
    valueLabelDisplay="on"
    marks={
      marks
        ? [
            { value: min, label: min },
            { value: max, label: max }
          ]
        : undefined
    }
    min={min}
    max={max}
    defaultValue={value}
    valueLabelFormat={() => `${labelText} is ${position}`}
    componentsProps={{
      valueLabel: {
        sx:
          marks && value === undefined
            ? { display: "none" }
            : theme => ({
                bgcolor: color,
                // color: theme.palette.secondary.on.color,
                // bgcolor: theme.palette.secondary.color,
                ...theme.typography.labelSm,
                borderRadius: theme.shape.round
              })
      },
      markLabel: {
        sx: theme => ({
          top: 8,
          ...theme.typography.labelSm,
          color: theme.palette.surface.on.color
        })
      },
      mark: {
        sx: {
          display: "none"
        }
      },
      root: {
        sx: {
          m: 0,
          p: "0 !important",
          color: "transparent !important"
        }
      },
      rail: {
        sx: {
          color,
          opacity: 1
        }
      },
      track: {
        sx: {
          display: "none"
        }
      },
      thumb: {
        sx: {
          height: 0,
          color: "transparent",
          "&:before": {
            boxShadow: "unset"
          }
        }
      }
    }}
    sx={{
      height: 4,
      borderRadius: theme => theme.shape.round
    }}
  />
);
function InfoCard({ value, label, reverse, bounds }) {
  const [expanded, setExpand] = useState(false);
  const [, lowerBound, upperBound] = bounds;
  const position =
    value < lowerBound ? "low" : upperBound < value ? "high" : "typical";
  const labelText = `${label === "cost" ? "$" : ""}${value}${
    label === "cost" ? "" : "%"
  }`;

  return (
    <FilledCard
      disabled
      thin
      surface={false}
      raise={false}
      // tone="surface.color"
      onClick={() => setExpand(expanded => !expanded)}
    >
      <CardHeader
        title={`${labelText}${label === "cost" ? " / hr" : ""}`}
        titleTypographyProps={{ variant: "labelLg", color: "surface.on.color" }}
        subheader={`${position} ${label}`}
        subheaderTypographyProps={{
          variant: "bodySm",
          color: "surface.on.color"
        }}
        action={
          <IconButton>
            <ExapndIcon
              sx={{
                color: "surface.on.color",
                transition: theme => theme.transitions.create("all"),
                transform: expanded ? "rotate(-180deg)" : undefined
              }}
            />
          </IconButton>
        }
        sx={{ p: 0 }}
      />
      <Collapse mountOnEnter unmountOnExit in={expanded}>
        <Stats
          value={value}
          reverse={reverse}
          position={position}
          bounds={bounds}
          labelText={labelText}
        />
      </Collapse>
    </FilledCard>
  );
}

function SubHeader({
  model,
  size,
  publisher = false,
  runs = false,
  license = false,
  delta = false
}) {
  const { utilities } = useStore();
  const items = [
    delta && 0.009 < model.delta ? (
      <Typography
        variant="labelMd"
        color={theme => theme.palette.colors.success[40]}
      >{`+${model.delta.toFixed(1)}%`}</Typography>
    ) : undefined,
    model.paper?.published
      ? utilities.paperTime(model.paper.published)
      : undefined,
    model.org,
    publisher ? model.publisher : undefined,
    license ? model.license : undefined,
    runs ? `${model.runs?.toLocaleString()} runs` : undefined
  ].filter(defined => defined);

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" width="100%">
      {items.map((content, index, array) => (
        <Fragment key={index}>
          <Typography
            color="surface.on.color"
            variant={size === "small" ? "bodySm" : "bodyMd"}
          >
            {content}
          </Typography>
          {index + 1 === array.length ? undefined : (
            <Box color="surface.on.color" mx={0.5}>
              ·
            </Box>
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
const Header = ({ model, Status, size = "medium", ...subheaderProps }) => (
  <CardHeader
    avatar={
      <GameBadge
        scoreTrending={model.scoreTrending}
        scoreSota={model.scoreSota}
      />
    }
    title={model.alias || model.displayName}
    titleTypographyProps={{
      color: "surface.on.color",
      variant: size === "small" ? "labelLg" : "titleMd"
    }}
    subheader={<SubHeader model={model} size={size} {...subheaderProps} />}
    subheaderTypographyProps={{
      color: "surface.on.color",
      sx: theme => theme.typography.lineClamp(3),
      variant: size === "small" ? "labelMd" : "labelLg"
    }}
    action={
      <Box px={1}>
        <Star entity={model} size={size} />
      </Box>
    }
    sx={{ p: 0 }}
  />
);
const Performance = ({ model }) =>
  model.aggregate?.performanceScore || model.cost ? (
    <Grid container spacing={1} pl={model.status ? 6 : undefined} width="100%">
      <Grid compact="auto">
        <InfoCard
          label="performance"
          bounds={[0, 26, 75, 100]}
          value={model.aggregate?.performanceScore}
        />
      </Grid>
      <Grid compact="auto">
        <InfoCard
          reverse
          bounds={[0, 1.5, 3.2, 32]}
          label="cost"
          value={model.cost}
        />
      </Grid>
    </Grid>
  ) : null;

// const MODEL = {
//   id: "eyJwYXBlcklEIjoiMjMwOS4wNzkxNSIsInB1Ymxpc2hlciI6ImFyeGl2In0=",
//   paper: [{ publisher: "arxiv", paperID: "2309.07915" }],
//   created: "2023-09-14T17:59:17.000Z",
//   name: "stable-diffusion-xl-refiner",
//   org: "stability",
//   cost: "2.30",
//   runs: 20232,
//   license: "mit",
//   publisher: "cvpr",
//   tasks: ["text generation"],
//   aggregate: {
//     performancePosition: 3,
//     performanceScore: 82,
//     costPosition: 10,
//     efficiencyPosition: 32
//   },
//   leaderboards: [
//     {
//       name: "minst",
//       performance: {
//         position: 10,
//         score: 72
//       },
//       cost: {
//         position: 10
//       },
//       efficiencyPosition: 32
//     },
//     {
//       name: "cif6",
//       performance: {
//         position: 100,
//         score: 21
//       },
//       cost: {
//         position: 100
//       },
//       efficiencyPosition: 32
//     }
//   ]
// };
