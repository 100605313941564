import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { Box, Skeleton, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses
} from "@mui/lab";
import useSWR from "swr";
//
import { useStore } from "../../../../service/mobx";
import { fetcherAPIServerRaw } from "../../../../service/graph";

export default function Distilled({ papers = [] }) {
  const sections = [
    ["trending", papers.filter(paper => paper.scoreTrending)],
    [
      "breakthroughs",
      papers.filter(paper => paper.scoreTrending === null).slice(0, 5)
    ]
  ].filter(([, array]) => array.length !== 0);

  return sections.length === 0 ? null : (
    <Box p={2} mt={-2} sx={theme => ({ borderRadius: theme.shape.md.round })}>
      <Typography variant="titleMd" color="surface.on.color">
        Changelog
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          }
        }}
      >
        {sections.map(([title, papers]) => (
          <Fragment key={title}>
            <SectionTitle title={title} />
            {papers.map((paper, index) => (
              <PaperDistill
                key={paper.id}
                paper={paper}
                isTrending={title !== "breakthroughs"}
                last={index + 1 === papers.length}
              />
            ))}
          </Fragment>
        ))}
      </Timeline>
    </Box>
  );
}

function PaperDistill({ paper, last = false, isTrending = false }) {
  const { utilities } = useStore();
  const [gist, setGist] = useState("");
  const paperTime = utilities.paperTime(paper.updated);
  const { data: res } = useSWR(
    `distill/${paper.publisher}/${paper.paperID}`,
    fetcherAPIServerRaw
  );

  useEffect(() => {
    if (res?.bodyUsed === false) {
      streamText(res, setGist);
    }
  }, [res]);

  return (
    <TimelineItem>
      <Separator disconnected={isTrending === false && last} />
      <TimelineContent variant="labelSm" color="secondary.color">
        {isTrending ? undefined : paperTime}
        {gist === "" ? (
          <Skeleton height={48} variant="text" />
        ) : (
          <Typography
            component={Link}
            variant="titleSm"
            color="surface.on.color"
            href={`/docs/${paper.publisher}/${paper.paperID}/paper`}
            sx={theme => ({
              display: "block",
              textDecoration: "none",
              transition: theme.transitions.create("all"),
              "&:hover": {
                color: "primary.color"
              }
            })}
          >
            {gist}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}

async function streamText(res, setGist) {
  try {
    let gist = "";
    const readableStream = res
      .clone()
      .body.pipeThrough(new TextDecoderStream())
      .getReader();

    do {
      var { done, value } = await readableStream.read();

      if (done === false) {
        gist += value;

        setGist(gist);
      }
    } while (done === false);
  } catch (error) {
    console.error(error);
  }
}

const SectionTitle = ({ title }) => (
  <TimelineItem>
    <Separator variant="outline" />
    <TimelineContent
      variant="labelMd"
      color="secondary.color"
      textTransform="capitalize"
    >
      {title}
    </TimelineContent>
  </TimelineItem>
);
const Separator = ({ variant, disconnected }) => (
  <TimelineSeparator>
    <TimelineDot
      variant={variant}
      sx={{
        p: 0,
        width: 8,
        height: 8,
        boxShadow: "unset",
        borderColor: variant === "outline" ? "outline.variant" : undefined,
        bgcolor: variant === "outline" ? "transparent" : "outline.variant"
      }}
    />
    {disconnected ? null : (
      <TimelineConnector sx={{ bgcolor: "outline.variant", width: "1px" }} />
    )}
  </TimelineSeparator>
);
