"use client";
import { Box, Button, Badge } from "@mui/material";
import { ArrowForward as Icon } from "@mui/icons-material";

import { useStore } from "../../../../service/mobx";

export function MoreButton({
  entity,
  url,
  count,
  m = "auto",
  text = "See more",
  justifyContent = "center"
}) {
  const { analytics, utilities } = useStore();

  url ??= `/search?sort=trending&type=${entity}s`;

  return (
    <Box
      display="flex"
      alignItems="center"
      m={m}
      justifyContent={justifyContent}
    >
      <Button
        href={url}
        endIcon={
          count ? (
            <Badge
              color="secondary"
              sx={{
                ml: 2.5,
                mr: 2,
                "& .MuiBadge-badge": {
                  typography: "labelSm",
                  color: "surface.on.color",
                  bgcolor: "surface.container.low"
                }
              }}
              badgeContent={`${utilities.formatNumber(count)}${
                1000 < count ? "+" : ""
              }`}
            >
              <div />
            </Badge>
          ) : (
            <Icon
              sx={{ width: 12, height: 12, color: "surface.variant.color" }}
            />
          )
        }
        sx={{
          px: 2,
          width: "fit-content",
          typography: "labelLg",
          color: "surface.on.color",
          borderRadius: theme => theme.shape.round
        }}
        onClick={() => analytics.track.event("More Click", { entity, url })}
      >
        {text}
      </Button>
    </Box>
  );
}
